import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/material';
import CartIcon from '@mui/icons-material/ShoppingCartRounded';
import AdminFilledIcon from '@mui/icons-material/AdminPanelSettings';
import AdminOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import moment from 'moment';
import { StaffingContext } from 'src/context/StaffingContext';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOpenSnackbar } from 'src/hooks/useOpenSnackbar';
import { logout } from 'src/actions/accountActions';
import { AUTH_URL, SERVER_URL } from 'src/settings';
import { SearchBar } from './SearchBar';
import UserDetailsPopup from './UserDetailsPopup';
import axios from 'axios';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { Root, ToolbarContainer, StyledToolbar, FlexGrow, Section, DividerStyled, StyledLink, DateText, StyledBadge, StyledMenu, MenuItemWithSeparator } from './components';

const TopBar = () => {
  const { isTablet } = useBreakpoints();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.account);
  const openSnackbar = useOpenSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [profilePicture, setProfilePicture] = useState('/static/icons/header/account.svg');
  const { showAdminView, toggleAdminView } = useContext(StaffingContext);

  let username = user['email'].split('@')[0];
  username = username.charAt(0).toUpperCase() + username.slice(1);
  const wpToken = user['wp_token'];

  // FETCH COUNT OF PRODUCTS IN METIGO CART FOR USER 
  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const email = user.email;
        const response = await axios.get(
          `https://medtigo.store/wp-json/user_cart_info/v2/cart/?email=${email}&request_mood=root`
        );
        const data = response.data;

        if (data.user_data_have) {
          setCartCount(data.number_of_item_in_cart);
        } else {
          setCartCount(0);
        }
      } catch (error) {
        setCartCount(0);
      }
    };

    if (user.email) {
      fetchCartCount();
    } else {
      setCartCount(0);
    }
  }, [user.email]);

  // SET THE PROFILE PICTURE OF USER IN HEADER
  useEffect(() => {
    if (user.profilePicture) {
      const pictureUrl = user.profilePicture.includes('http')
        ? user.profilePicture
        : `${SERVER_URL}${user.profilePicture}`;
      const isPlaceholder =
        pictureUrl === 'https://connect.medtigo.com/no-photo.jpg';
      setProfilePicture(
        isPlaceholder ? '/static/icons/header/account.svg' : pictureUrl
      );
    }
  }, [user]);

  const showUserMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setAnchorEl(null);
  };

  // METHODS TO REDIRECT TO PAGES FROM USER MENU
  const goToProfileSettings = () => {
    history.push('/setting');
    setAnchorEl(null);
  };

  const goToNewsletter = () => {
    window.open(
      `https://medtigo.com/newsletter-edit/?t=${user.wp_token}`,
      '_blank'
    );
    setAnchorEl(null);
  };

  const goToMyOrders = () => {
    history.push('/setting', { activeTab: 'orders' });
  };

  const goToMedtigoOrange = () => {
    history.push('/medtigo-orange');
    setAnchorEl(null);
  };
  
  const goToCart = () => {
    const cartUrl = `https://medtigo.store/cart?t=${wpToken}`;
    window.location.href = cartUrl;
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.clear();
      window.location.href = `${AUTH_URL}?path=logout&route=/state-licensing`;
    } catch {
      openSnackbar('Unable to logout', 'error');
    }
    setAnchorEl(null);
  };

  // MENU COMPONENT
  const UserMenu = () => {
    const displayName = user['first_name']
    ? `${user['first_name']} ${user['last_name']}`
    : username;

    return (
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItemWithSeparator
          icon={<img src="/static/icons/header/useraccount.svg" alt="user account" />}
          text={
            <Box
              sx={{
                maxWidth: 200, // Adjust width as needed
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {displayName}
            </Box>
          }
          onClick={goToProfileSettings}
        />
        {isTablet && (user.role === 'admin' || user.role === 'hospital_admin' || user.role === 'department_admin') && (
          <MenuItemWithSeparator
            icon={
              showAdminView ? <AdminOutlinedIcon sx={{ color: '#1C5087' }}/> : <AdminFilledIcon sx={{ color: '#1C5087' }}/>
            }
            text={
              <Box component="span" sx={{ color: '#1C5087' }}>
                {showAdminView ? 'User' : 'Admin'} View
              </Box>
            }
            onClick={toggleAdminView}
          />
        )}
        {user.isMedtigoOrange && (
          <MenuItemWithSeparator
            icon={
              <img
                src="/static/images/medtigoOrange/crown.svg"
                alt="medtigo Orange"
              />
            }
            text="Medtigo Orange"
            onClick={goToMedtigoOrange}
          />
        )}
        <MenuItemWithSeparator
          icon={<img src="/static/icons/header/mailicon.svg" alt="mail" />}
          text="Newsletter"
          onClick={goToNewsletter}
        />
        <MenuItemWithSeparator
          icon={
            <img src="/static/icons/header/profileSetting.svg" alt="profileSetting" />
          }
          text="Profile Settings"
          onClick={goToProfileSettings}
        />
        <MenuItemWithSeparator
          icon={<img src="/static/icons/header/myorders.svg" alt="orders" />}
          text="My Orders"
          onClick={goToMyOrders}
        />
        <MenuItemWithSeparator
          icon={<img src="/static/icons/header/logout.svg" alt="logout" />}
          text="Log Out"
          onClick={handleLogout}
          showseparator={false}
        />
      </StyledMenu>
    );
  };

  return (
    <>
      <Root position="static" elevation={0}>
        <ToolbarContainer>
          <StyledToolbar>
            <Section>
              <DateText>{moment().format('MMMM D, YYYY')}</DateText>
              <DividerStyled />
              <StyledLink to="/state-licensing">
                <img
                  src="/static/icons/header/notifications.svg"
                  alt="Notifications"
                />
                {!isTablet && 'Notification Center'}
              </StyledLink>
              <DividerStyled />
              <Box
                component="a"
                href="tel:+413-419-0592"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  textDecoration: 'none',
                  color: 'inherit'
                }}
              >
                <img src="/static/icons/header/phone.svg" alt="Phone" />
                {!isTablet && '413-419-0592'}
              </Box>
              <DividerStyled />
              <div onClick={() => setOpenSearchDialog(true)}>
                <img
                  src="/static/icons/header/search.svg"
                  alt="Search"
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Section>
            <FlexGrow />
            <Section>
              <Box sx={{ display: 'flex', gap: 1 }} onClick={goToCart}>
                {!isTablet && 'Cart'}
                <StyledBadge
                  badgeContent={cartCount || 0}
                  showZero
                >
                  <CartIcon fontSize="small" />
                </StyledBadge>
              </Box>
              <DividerStyled />
              <Box
                sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}
                onClick={showUserMenu}
              >
                {!isTablet &&
                  (user.first_name
                    ? `${user.first_name} ${user.last_name}`
                    : username)}
                <img
                  src={profilePicture}
                  alt="User"
                  style={{ borderRadius: '50%', width: '27px', height: '27px' }}
                />
              </Box>
              <UserMenu />
            </Section>
          </StyledToolbar>
        </ToolbarContainer>
      </Root>

      <UserDetailsPopup />
      <SearchBar
        openSearchDialog={openSearchDialog}
        setOpenSearchDialog={setOpenSearchDialog}
      />
    </>
  );
}

export default TopBar;