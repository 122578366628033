import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

export default function SimpleBackdrop({ page, open }) {
  return (
    <Backdrop
      open={open}
      style={{
        zIndex: 1300,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <CircularProgress color="inherit" />
      <div style={{ marginTop: '10px', fontSize: '20px' }}>Redirecting to {page}</div>
    </Backdrop>
  );
}
