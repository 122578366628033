import * as React from 'react';
import learningService from "../services/learningService";
import {useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import useroptionsServices from 'src/services/userOptionsService';
import { courseLanguages } from 'src/appConstants';

export const LearningContext = React.createContext({});

export const LearningContextProvider = ({children}) => {
  const user = useSelector((state) => state.account.user);
  const [allCoursesData, setAllCoursesData] = React.useState([]);
  const [coursesData, setCoursesData] = React.useState([]);
  const [shortCoursesData, setShortCoursesData] = React.useState([]);
  const [CMECoursesData, setCMECoursesData] = React.useState([]);
  const [activeCourse, setActiveCourse] = React.useState(null);
  const [activeQuiz, setActiveQuiz] = React.useState(null);
  const [blogsData, setBlogsData] = React.useState(null);
  const [isBlogsLoading, setIsBlogsLoading] = React.useState(false);
  const [isCoursesLoading, setIsCoursesLoading] = React.useState(false);
  const [progressSubmitted, setProgressSubmitted] = React.useState(true);
  const location = useLocation();
  const [userSubscription, setUserSubscription] = React.useState([]);
  const [language, setLanguage]=React.useState();

  const getUserOption = async()=>{
    if(user){
      const res = await useroptionsServices.getUserOptions();
      let selected_language = res?res.selected_language:courseLanguages[0].id;
      selected_language = selected_language?selected_language : courseLanguages[0].id;
      const selected_language_name = courseLanguages.find(it=>it.id==selected_language).name;
      setLanguage(selected_language_name);
    }
  }

  const fetchCourses = async (id) => {
    setIsCoursesLoading(true);
    try {      
      const coursesData = await learningService.getAllCourses(id);
      if(id){
        setActiveCourse(coursesData);
      } else {
        setAllCoursesData([...coursesData]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsCoursesLoading(false);
  };

  React.useEffect(() => {
    setCoursesData(allCoursesData.filter(course => course['course_type'] === 'required'));
    setShortCoursesData(allCoursesData.filter(course => course['course_type'] === '15-minute'));
    setCMECoursesData(allCoursesData.filter(course => course['course_type'] === 'cme'));
  }, [allCoursesData]);

  const updateCourseProgress = async (lessonID, courseID) => {
    try {
      const response = await learningService.updateCourseProgress(lessonID, courseID);
  
      if (lessonID == 21699 || lessonID == 21365 || lessonID == 12239 || lessonID == 20966) {
        setProgressSubmitted(true);
      }
  
      if (allCoursesData?.length > 0) {
        const allCourses = [...allCoursesData]; // Clone the array to avoid mutation
        const activeIndex = allCourses.findIndex(course => course.id === activeCourse.id);
  
        if (activeIndex !== -1) {
          let activeLessons = allCourses[activeIndex]['lessons'];
          activeLessons = activeLessons.map(lesson => 
            lesson['ID'] === lessonID ? { ...lesson, completed: 1 } : lesson
          );
          allCourses[activeIndex]['lessons'] = activeLessons;
          setAllCoursesData(allCourses);
        }
      }
    } catch (error) {
      console.error('Error updating course progress:', error);
    }
  };

  const fetchSingleCourse = async (id) => {
    if (!id || isNaN(id)) {
      return console.log('No ID found/Invalid ID');
    }
    fetchCourses(id);
  };

  const fetchQuizzesData = async (id) => {
    setActiveQuiz(null);
    const quizzesData = await learningService.getQuizzesData(id);
    setActiveQuiz(quizzesData);
  };

  const getCourseSubscriptionData = async () => {
    if(user){
    const subscriptionData = await learningService.getCourseSubscriptionData(user.id);

      if (subscriptionData) {
        setUserSubscription(subscriptionData);
      } else {
        setUserSubscription([]);
      }
    }
  };

  React.useEffect(() => {
    getCourseSubscriptionData();
  }, []);
  

  React.useEffect(() => {
    const isShortCourse = activeCourse ? activeCourse.course_type === '15-minute' : false;
    activeCourse && !isShortCourse && fetchQuizzesData(activeCourse.id);
  }, [activeCourse]);

  React.useEffect(() => {
    const url = window.location.href.split('/');
    user && isNaN(url[url.length - 1]) && !(coursesData && coursesData.length) && fetchCourses();
  }, [location]);

  React.useEffect(()=>{getUserOption()},[])

  const defaultContext = {
    isCoursesLoading,
    coursesData,
    setActiveCourse,
    activeCourse,
    activeQuiz,
    // blogsData,
    shortCoursesData,
    isBlogsLoading,
    progressSubmitted,
    setProgressSubmitted,
    fetchSingleCourse,
    updateCourseProgress,
    CMECoursesData,
    userSubscription,
    language
  };

  return (
    <LearningContext.Provider
      value={defaultContext}
    >
      {children}
    </LearningContext.Provider>
  );
};
