import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, FormControl, FormControlLabel, RadioGroup, Radio, Typography, Stack, CircularProgress, TextareaAutosize, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useOpenSnackbar } from 'src/hooks/useOpenSnackbar';
import alertPage from 'src/services/alertPage';
import { motion } from 'framer-motion';

const StarRating = ({ value, onChange, error }) => {
  const labels = ['Very Poor', 'Poor', 'Average', 'Good', 'Excellent'];

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        {labels.map((label, index) => (
          <Box
            key={index}
            onClick={() => onChange(index + 1)}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={index < value ? '#FFD700' : '#ccc'}
              width="42px"
              height="42px"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.2 }}
            >
              <path d="M12 .587l3.668 7.435 8.21 1.193-5.938 5.788 1.402 8.162L12 18.896l-7.342 3.861 1.402-8.162L.122 9.215l8.21-1.193L12 .587z" />
            </motion.svg>
            <Typography variant="caption">{label}</Typography>
          </Box>
        ))}
      </Box>
      {error && (
        <Typography variant="caption" color="error" mt={1}>
          {error}
        </Typography>
      )}
    </>
  );
};

function FeedbackView() {
  const openSnackbar = useOpenSnackbar();
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const formik = useFormik({
    initialValues: {
      Rating: 0,
      Feedback: '',
      contact: 'yes'
    },
    validationSchema: Yup.object({
      Rating: Yup.number()
        .min(1, 'Please select a rating')
        .required('Rating is required'),
      Feedback: Yup.string().required('Feedback is required'),
      contact: Yup.string().required('Please select an option')
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const payload = {
          User_Name: `${queryParams.get('first_name') || ''} ${queryParams.get(
            'last_name'
          ) || ''}`,
          UserId: queryParams.get('id') || '',
          ...values
        };

        await alertPage.submitFeedbackFromEmail(payload);
        openSnackbar('Feedback Submitted.', 'success');
        resetForm(); // Reset the form after successful submission
      } catch (error) {
        console.error(error);
        openSnackbar('Something went wrong.', 'error');
      } finally {
        setSubmitting(false); // End the submitting state
      }
    }
  });

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        justifyContent: 'center',
        background: '#F8F9FA'
      }}
    >
      {/* Header */}
      <img
        src="https://medtigo.com/wp-content/uploads/2024/05/medtigo_2-1.svg"
        alt="Medtigo Logo"
        height="50px"
        width="168px"
      />
      
      {/* Feedback Form */}
      <Box
        sx={{
          maxWidth: 600,
          width: '100%',
          background: 'white',
          borderRadius: 2,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          p: 4
        }}
      >
        <Stack spacing={3}>
          {/* Header */}
          <Box textAlign="center">
            <img
              src="/static/icons/licensing/feedback.svg"
              alt="Feedback"
              style={{ height: '40px' }}
            />
            <Typography variant="h5" fontWeight="bold">
              Feedback
            </Typography>
          </Box>

          {/* Star Rating */}
          <Box>
            <Typography align="center" fontWeight={600} mb={1}>
              Your overall satisfaction with our service
            </Typography>
            <StarRating
              value={formik.values.Rating}
              onChange={newValue => formik.setFieldValue('Rating', newValue)}
              error={formik.touched.Rating && formik.errors.Rating}
            />
          </Box>

          {/* Feedback Text */}
          <Box>
            <Typography mb={2} fontWeight={600}>
              What is the main reason for your score?
            </Typography>
            <TextareaAutosize
              placeholder="Write your feedback here..."
              value={formik.values.Feedback}
              onChange={formik.handleChange}
              name="Feedback"
              style={{
                minHeight: 50,
                maxHeight: 150,
                resize: 'vertical',
                width: '100%',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: `1px solid ${
                  formik.touched.Feedback && formik.errors.Feedback
                    ? theme.palette.error.main
                    : '#ccc'
                }`,
                outline: 'none'
              }}
            />
            {formik.touched.Feedback && formik.errors.Feedback && (
              <Typography variant="caption" color="error">
                {formik.errors.Feedback}
              </Typography>
            )}
          </Box>

          {/* Contact Option */}
          <FormControl>
            <Typography fontWeight={600}>
              Would you like a representative to contact you?
            </Typography>
            <RadioGroup
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              row
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            // disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              'Submitting...'
            ) : (
              'Submit'
            )}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default FeedbackView;
